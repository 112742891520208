import { Button, ButtonGroup, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, LinearProgress, MenuItem, TextField, Theme, withStyles, WithStyles } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { debounce } from "lodash";
import React from "react";
import { RelayPerson } from "../../../model/Entry";
import { Person } from "../../../model/Person";
import { ApiBackend } from "../../../providers/apibackend";
import withToaster, { IToasterContext } from "../../Common/Toaster";
import { IColumnDefinition } from "../../Common/EnhancedTable/models";
import EnhancedTable from "../../Common/EnhancedTable/EnhancedTable";

interface IProps {
    onSave: (relayMember: RelayPerson) => void;
    onAbort: () => void;
}

interface IState {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    birthDay: string;
    gender: string;
    vasaId: string;

    loading: boolean;
    persons: Person[];
    maxRows: number;
    errors: { [key: string]: boolean };
}

const personToRelayMember = (person: Person): RelayPerson => {
    let entry: RelayPerson = {
        personId: person.id,
        firstName: person.firstName,
        lastName: person.lastName,
        email: person.email,
        phone: person.mobileNumber,
        address: person.address,
        postalCode: person.postCode,
        city: person.city,
        country: person.country,
        nationality: person.nationality,
        dateOfBirthYear: null,
        dateOfBirthMonth: null,
        dateOfBirthDay: null,
        sex: null
    };

    if (person.birthDay) {
        const dateParts = person.birthDay.split("-");
        entry.dateOfBirthYear = +dateParts[0];
        entry.dateOfBirthMonth = +dateParts[1];
        entry.dateOfBirthDay = +dateParts[2];
    }

    if (person.gender) {
        entry.sex = person.gender === "male" ? "M" : "W";
    }

    return entry;
};

class AddRelayMemberDialog extends React.Component<WithStyles & IProps & IToasterContext, IState> {
    private readonly maxDate = new Date().toLocaleDateString("sv-SE");
    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps & IToasterContext) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            birthDay: `${new Date().getFullYear()}-01-01`,
            gender: "",
            vasaId: "",
            loading: false,
            persons: null,
            maxRows: 100,
            errors: {}
        };

        this.api = new ApiBackend();
    }

    render() {
        const { classes } = this.props;
        const { lastName, firstName, email, phone, birthDay, gender, vasaId, errors } = this.state;

        const columnDefinitions: IColumnDefinition<Person>[] = [
            {
                renderCell: (row) => {
                    return (
                        <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                                this.selectPerson(row);
                            }}>
                            <ArrowForward fontSize="medium" />
                        </IconButton>
                    );
                }
            },
            { propName: "lastName", label: "Efternamn" },
            { propName: "firstName", label: "Förnamn" },
            { propName: "vasaId", label: "VASA-ID" },
            { propName: "birthDay", label: "Födelsedatum" },
            { propName: "email", label: "E-post" },
            { propName: "mobileNumber", label: "Telefon" },
        ];

        return (<>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="add-relay-member-title"
                open={true}>
                <DialogTitle id="add-relay-member-title">Sök efter person</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Efternamn"
                                value={lastName}
                                error={errors["lastName"]}
                                onChange={(e) => {
                                    this.setState({
                                        lastName: e.target.value
                                    }, () => {
                                        this.performSearch();
                                    });
                                }}
                                InputLabelProps={{ shrink: true }}
                                helperText={errors["lastName"] ? "Vänligen ange ett efternamn" : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Förnamn"
                                value={firstName}
                                error={errors["firstName"]}
                                onChange={(e) => {
                                    this.setState({
                                        firstName: e.target.value
                                    }, () => {
                                        this.performSearch();
                                    });
                                }}
                                InputLabelProps={{ shrink: true }}
                                helperText={errors["firstName"] ? "Vänligen ange ett förnamn" : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="E-post"
                                value={email}
                                error={errors["email"]}
                                onChange={(e) => {
                                    this.setState({
                                        email: e.target.value
                                    }, () => {
                                        this.performSearch();
                                    });
                                }}
                                InputLabelProps={{ shrink: true }}
                                helperText={errors["email"] ? "Felaktig E-post-adress" : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Telefon"
                                value={phone}
                                error={errors["phone"]}
                                onChange={(e) => {
                                    this.setState({
                                        phone: e.target.value
                                    }, () => {
                                        this.performSearch();
                                    });
                                }}
                                InputLabelProps={{ shrink: true }}
                                helperText={errors["phone"] ? "Felaktigt telefonnummer" : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                select
                                style={{ flex: 1, margin: '0 3px' }}
                                label="Kön (endast för ny person)"
                                InputLabelProps={{ shrink: true }}
                                value={gender}
                                fullWidth
                                onChange={(e) => {
                                    this.setState({
                                        gender: e.target.value,
                                        errors: {}
                                    });
                                }}
                                error={errors["gender"]}
                                helperText={errors["gender"] ? "Vänligen välj ett kön" : ""}
                            >
                                <MenuItem value="male">Man</MenuItem>
                                <MenuItem value="female">Kvinna</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                style={{ flex: 1, margin: '0 3px' }}
                                type="date"
                                inputProps={{ min: '1800-01-01', max: this.maxDate }}
                                label="Födelsedatum (endast för ny person)"
                                InputLabelProps={{ shrink: true }}
                                value={birthDay}
                                error={errors["birthDay"]}
                                onChange={(e) => {
                                    this.setState({
                                        birthDay: e.target.value,
                                        errors: {}
                                    });
                                }}
                                helperText={errors["birthDay"] ? "Felaktigt födelsedatum" : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="VASA-ID (endast sök)"
                                value={vasaId}
                                onChange={(e) => {
                                    this.setState({
                                        vasaId: e.target.value
                                    }, () => {
                                        this.performSearch();
                                    });
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup color="secondary">
                        <Button onClick={this.performSearch}>Sök</Button>
                        <Button onClick={this.addNewPerson}>Lägg till som ny person</Button>
                    </ButtonGroup>
                </DialogActions>
                <DialogContent dividers>
                    {this.state.loading &&
                        <LinearProgress color="secondary" />
                    }
                    <div style={{ height: 400, width: '100%' }}>
                        <EnhancedTable<Person>
                            columnDefinitions={columnDefinitions}
                            data={this.state.persons ?? []}
                            pageSize={10}
                            maxRows={this.state.maxRows}
                            paginationMode="client"
                            sortingMode="client"
                            loading={this.state.loading}
                            dense
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => this.props.onAbort()}
                        color="secondary">Avbryt</Button>
                </DialogActions>
            </Dialog>
        </>);
    }

    private addNewPerson = async (): Promise<void> => {
        if (!this.isNewPersonValid()) {
            return;
        }

        const person = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            mobileNumber: this.state.phone,
            gender: this.state.gender,
            birthDay: this.state.birthDay
        } as Person;

        await this.api.addPerson(person)
            .then((addedPerson: Person) => {
                this.props.onSave(personToRelayMember(addedPerson));
            }).catch(() => {
                this.props.showToast("Ett fel uppstod när personen försökte sparas.", "error");
            });
    };

    private isNewPersonValid = (): boolean => {
        const { firstName, lastName, email, phone, gender, birthDay, errors } = this.state;

        let hasErrors = false;

        if (!firstName) {
            errors.firstName = true;
            hasErrors = true;
        } else {
            delete errors.firstName;
        }

        if (!lastName) {
            errors.lastName = true;
            hasErrors = true;
        } else {
            delete errors.lastName;
        }

        // Tillfälligt lånat från: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
        const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (email && !emailRegexp.test(email)) {
            errors.email = true;
            hasErrors = true;
        } else {
            delete errors.email;
        }

        const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
        if (phone && !phoneRegExp.test(phone)) {
            errors.phone = true;
            hasErrors = true;
        } else {
            delete errors.phone;
        }

        if (!gender) {
            errors.gender = true;
            hasErrors = true;
        } else {
            delete errors.gender;
        }

        const birthDateRegexp = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
        if (!birthDateRegexp.test(birthDay)) {
            errors.birthDay = true;
            hasErrors = true;
        } else {
            if (Date.parse(birthDay) > Date.parse(this.maxDate)) {
                errors.birthDay = true;
                hasErrors = true;
            } else {
                delete errors.birthDay;
            }
        }

        this.setState({ errors: errors });

        return !hasErrors;
    };

    private selectPerson = (person: Person): void => {
        this.props.onSave(personToRelayMember(person));
    };

    private performSearch = debounce(async () => {
        const { firstName, lastName, email, phone, vasaId, maxRows } = this.state;
        this.setState({ loading: true, errors: {} });

        const response = await this.api.searchPersons({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            vasaid: vasaId,
            noCount: true,
            begin: 0,
            limit: maxRows
        });

        this.setState({
            persons: response?.persons,
            loading: false
        });
    }, 500);
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withToaster(withStyles(useStyles)(AddRelayMemberDialog));
