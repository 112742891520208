import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, WithStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ArrayHelpers, FieldArray, FieldArrayRenderProps, FormikProps } from "formik";
import React from "react";
import { Club } from "../../../model/Club";
import { EntryStatusTypes, RaceStatusTypes, SexTypes, SexTypesLookup } from "../../../model/CommonTypes";
import { Entry, RelayPerson } from "../../../model/Entry";
import { Person } from "../../../model/Person";
import { Product } from "../../../model/Product";
import { ProductVariantRaceData } from "../../../model/ProductVariantRaceData";
import { ApiBackend } from "../../../providers/apibackend";
import FormCbxField from "../../Common/FormCbxField";
import FormClubField from "../../Common/FormClubField";
import FormReadonlyField from "../../Common/FormReadOnlyField";
import FormSelectField from "../../Common/FormSelectField";
import FormTextField from "../../Common/FormTextField";
import AddRelayMemberDialog from "./AddRelayMemberDialog";
import EntryAddonsCard from "./EntryAddonsCard";
import EntryCustomFieldsCard from "./EntryCustomFieldsCard";
import EntryPersonCard from "./EntryPersonCard";
import { vasaloppetDateTimeGetterFormatter, vasaloppetTimeGetterFormatter } from "../../../utilities/date";
import EntrySeedingCard from "./EntrySeedingCard";
import EntryTransferCard from "./EntryTransferCard";
import VasaloppetTooltip from "../../Common/VasaloppetTooltip";
import { Alert } from "@material-ui/lab";

interface IProps extends FormikProps<Entry> {
    person: Person;
    entryProduct: Product;
    validClubs: Club[];
    onShowPerson: () => void;
    onRequestChangePerson: () => void;
    onRequestReloadEntry: () => void;
}

interface IState {
    relayMemberIndex: number;
    showAddRelayMemberDialog: boolean;
    confirmResendStartCertificate: boolean;
}

const emptyRelayMember = (): RelayPerson => {
    return {} as RelayPerson;
};

const entryToRelayMember = (entry: Entry, person: Person): RelayPerson => {
    return {
        personId: entry.personId,
        firstName: entry.firstName,
        lastName: entry.lastName,
        email: entry.email,
        phone: entry.phone,
        address: entry.address,
        postalCode: entry.postalCode,
        city: entry.city,
        country: entry.country,
        nationality: entry.nationality,
        dateOfBirthYear: entry.dateOfBirthYear,
        dateOfBirthMonth: entry.dateOfBirthMonth,
        dateOfBirthDay: entry.dateOfBirthDay,
        sex: person.gender === "male" ? "M" : "W"
    };
};

class EditEntryFormContent extends React.Component<WithStyles & IProps, IState> {
    state: IState;

    private hasTeamMembers: boolean;

    constructor(props: WithStyles & IProps) {
        super(props);

        const productVariantRaceData = this.getProductVariantRaceData();

        this.hasTeamMembers = productVariantRaceData.type === "team" || productVariantRaceData.type === "relay";

        if (productVariantRaceData && this.hasTeamMembers) {
            this.fixRelayMembersArrayIfMissingRows(productVariantRaceData.numberOfTeamMembers, this.props.values.relayMembers);
        }
    }

    render(): JSX.Element {
        const { classes, entryProduct, validClubs, values, setFieldValue } = this.props;

        const entryHasChip = !!values.chip;
        const relayMembers = this.props.values.relayMembers ?? [];

        const isRelay = (): boolean => {
            const variant = entryProduct.variants?.find(v => v.Id === values.variantId);

            return variant?.productVariantRaceData?.type === "relay";
        };

        const isRace = entryProduct.type === "race";

        return (<>
            {entryHasChip &&
                <Box style={{ width: '100%' }}>
                    <Alert severity="warning">
                        Deltagaren har hämtat ut deltagarmaterial.
                    </Alert>
                </Box>
            }
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                {/* ROW 1 */}
                <Grid item xs={3}>
                    <FormTextField {...this.props}
                        propName="bibtext"
                        label="Nummerlapp"
                    />
                </Grid>

                <Grid item xs={3}>
                    {isRace && entryProduct.startGroups ?
                        <FormSelectField {...this.props}
                            propName="startgroup"
                            label="Startgrupp"
                            isClearable={true}
                            onChange={() => (e: any) => {
                                setFieldValue("startgroup", e.target.value);
                                if (this.props.entryProduct.bibAllocationActive) {
                                    if (!(this.props.values.bibtext && (this.props.values.bibtext.startsWith("P") || this.props.values.bibtext.startsWith("V")))) {
                                        setFieldValue("bibtext", "");
                                    }
                                }
                            }}
                            onClear={() => {
                                setFieldValue("startGroupLocked", false);
                            }}
                        >
                            {entryProduct.startGroups.map((x, idx) => {
                                if (!x.participantCategory || x.participantCategory == this.props.values.variantId) {
                                    const remainingSellable = x.numberOfSellablePlaces - x.numberOfOccupiedPlaces - x.numberOfReservedPlaces;
                                    const remainingTotal = x.maxParticipants - x.numberOfOccupiedPlaces - x.numberOfReservedPlaces; // Skippa efter dialog

                                    return <MenuItem
                                        key={idx}
                                        value={x.id}
                                    >
                                        {x.name}, {vasaloppetTimeGetterFormatter(x.startTime)}, {remainingTotal} lediga ({remainingSellable} publikt lediga)
                                    </MenuItem>;
                                }
                            })}
                        </FormSelectField>
                        :
                        <FormReadonlyField {...this.props}
                            propName="startgroup"
                            label="Startgrupp"
                        />
                    }
                </Grid>

                <Grid item xs={3}>
                    <FormCbxField {...this.props}
                        disabled={!isRace || !this.props.values.startgroup}
                        propName="startGroupLocked"
                        label="Startgrupp låst"
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormTextField {...this.props}
                        disabled
                        propName="ageClassKey"
                        label="Åldersklass"
                    />
                </Grid>

                {/* ROW 2 */}
                <Grid item xs={3}>
                    <FormTextField {...this.props}
                        propName="prePackingGroup"
                        label="Packningsgrupp"
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormClubField {...this.props}
                        propName="clubName"
                        label="Klubb"
                        validClubs={validClubs}
                        onChange={(value: string) => {
                            setFieldValue("clubName", value);
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormTextField {...this.props}
                        propName="team"
                        label="Lag"
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormSelectField {...this.props}
                        propName="status"
                        label="Status"
                    >
                        {EntryStatusTypes.map((x, idx) => {
                            return <MenuItem key={idx} value={x}>{x}</MenuItem>;
                        })}
                    </FormSelectField>
                </Grid>

                {/* ROW 3 */}
                <Grid item xs={3}>
                    <FormSelectField {...this.props}
                        propName="raceStatus"
                        label="Race Status"
                    >
                        {RaceStatusTypes.map((x, idx) => {
                            return <MenuItem key={idx} value={x}>{x}</MenuItem>;
                        })}
                    </FormSelectField>
                </Grid>

                <Grid item xs={3}>
                    <FormCbxField {...this.props}
                        propName="dsq"
                        label="Diskvalificerad"
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormTextField {...this.props}
                        propName="dsqReason"
                        label="Anledning"
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormReadonlyField {...this.props}
                        propName="chip"
                        label="Chip"
                    />
                </Grid>

                {/* ROW 4 */}
                <Grid item xs={3}>
                    {isRelay() &&
                        <FormSelectField {...this.props}
                            propName="sex"
                            label="Kön"
                        >
                            {SexTypes.map((x) => {
                                return <MenuItem key={x} value={x}>{SexTypesLookup[x]}</MenuItem>;
                            })}
                        </FormSelectField>
                    }
                </Grid>

                <Grid item xs={9}></Grid>

                <Grid item xs={3}>
                    <FormReadonlyField {...this.props}
                        valueFormatter={vasaloppetDateTimeGetterFormatter}
                        propName="created"
                        label="Skapad datum"
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormReadonlyField {...this.props}
                        valueFormatter={vasaloppetDateTimeGetterFormatter}
                        propName="updated"
                        label="Uppdaterad datum"
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormTextField {...this.props} disabled
                        propName="MT_regId"
                        label="Registration-ID"
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormTextField {...this.props} disabled
                        propName="externalId"
                        label="Sportspro-ID"
                    />
                </Grid>

                {this.props.values.MT_MTQRcontent &&
                    <Grid container className={classes.root} spacing={2} style={{ marginTop: "1em" }}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    title="Startbevis"
                                />
                                <CardContent>
                                    <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                        {this.props.values.startCertificateSent &&
                                            <Grid item xs={4} style={{ borderLeft: '8px solid green' }}>
                                                <FormReadonlyField {...this.props}
                                                    valueFormatter={vasaloppetDateTimeGetterFormatter}
                                                    propName="startCertificateSent"
                                                    label="Startbevis skickat"
                                                /></Grid>}
                                        {!this.props.values.startCertificateSent &&
                                            <Grid item xs={4}>
                                                <TextField disabled
                                                    label="Startbevis skickat"
                                                    value={"Inte skickat"}
                                                /></Grid>}

                                        <Grid item xs={4}>
                                            <Button onClick={() => {
                                                window.open(ApiBackend.getStartCertificatePdfUrl(this.props.values.id), "_blank");
                                            }}>
                                                Hämta startbevis som PDF
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img src={ApiBackend.getStartCertificateQrCodeUrl(this.props.values.id)} style={{ width: 64, height: 64 }} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button onClick={() => {
                                                this.setState({ confirmResendStartCertificate: true });
                                            }}>
                                                Skicka om startbevis
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                }

                {this.props.values.entryTransfer &&
                    <Grid container className={classes.root} spacing={2} style={{ marginTop: "1em" }}>
                        <Grid item xs={12}>
                            <EntryTransferCard
                                entry={this.props.values}
                            />
                        </Grid>
                    </Grid>
                }

                <Grid container className={classes.root} spacing={2} style={{ marginTop: "1em" }}>
                    <Grid item xs={12}>
                        <EntryCustomFieldsCard {...this.props} />
                    </Grid>
                </Grid>

                <Grid container className={classes.root} spacing={2} style={{ marginTop: "1em" }}>
                    <Grid item xs={12}>
                        <EntryAddonsCard {...this.props} />
                    </Grid>
                </Grid>

                {this.props.entryProduct && this.props.entryProduct.startGroups && this.props.entryProduct.startGroups.some((x) => { return !!x.seedingTable }) &&
                    <Grid container className={classes.root} spacing={2} style={{ marginTop: "1em" }}>
                        <Grid item xs={12}>
                            <EntrySeedingCard entryProduct={this.props.entryProduct} person={this.props.person} title="Seedning" />
                        </Grid>
                    </Grid>
                }

                <Grid container className={classes.root} spacing={2} style={{ marginTop: "1em" }}>
                    <Grid item xs={12}>
                        <EntryPersonCard {...this.props} expanded={true} />
                    </Grid>
                </Grid>


                {this.hasTeamMembers && <>
                    <FieldArray
                        name="relayMembers"
                        render={arrayHelpers => (
                            <>
                                <Grid container className={classes.root} spacing={2} style={{ marginTop: 10 }}>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardHeader
                                                className={classes.cardHeader}
                                                title={`Lagmedlemmar`}
                                            />
                                            {!!relayMembers.length && <CardContent>
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell>Efternamn</TableCell>
                                                                <TableCell>Förnamn</TableCell>
                                                                <TableCell>E-post</TableCell>
                                                                <TableCell>Tel</TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {relayMembers.map((relayMember: RelayPerson, idx) => {
                                                                return <TableRow key={idx}>
                                                                    <TableCell>{idx + 1}</TableCell>
                                                                    <TableCell>{relayMember.lastName}</TableCell>
                                                                    <TableCell>{relayMember.firstName}</TableCell>
                                                                    <TableCell>{relayMember.email}</TableCell>
                                                                    <TableCell>{relayMember.phone}</TableCell>
                                                                    <TableCell align="right">
                                                                        {idx !== 0 && <ExpandLess
                                                                            className={classes.icon}
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                this.moveSlotUp(idx, arrayHelpers);
                                                                            }}
                                                                        />
                                                                        }
                                                                        {idx !== relayMembers.length - 1 && <ExpandMore
                                                                            className={classes.icon}
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                this.moveSlotDown(idx, arrayHelpers);
                                                                            }}
                                                                        />
                                                                        }
                                                                        <VasaloppetTooltip title="Välj person att tilldela till">
                                                                            <EditIcon
                                                                                className={classes.icon}
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    this.showAddRelayMemberDialog(idx);
                                                                                }}
                                                                            />
                                                                        </VasaloppetTooltip>
                                                                        {(relayMember.personId && relayMember.personId.length) && <DeleteIcon
                                                                            className={classes.icon}
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                this.unassignSlot(idx, arrayHelpers, relayMember)
                                                                            }}
                                                                        />
                                                                        }
                                                                        <VasaloppetTooltip title="Tilldela sträcka till lagledare">
                                                                            <AssignmentIndIcon
                                                                                className={classes.icon}
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    this.assignToTeamLeader(idx, arrayHelpers, values);
                                                                                }}
                                                                            />
                                                                        </VasaloppetTooltip>
                                                                    </TableCell>
                                                                    <TableCell size="small">{idx + 1}</TableCell>
                                                                </TableRow>;
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </CardContent>
                                            }
                                        </Card>
                                    </Grid>
                                </Grid>

                                {this.state && this.state.showAddRelayMemberDialog &&
                                    <AddRelayMemberDialog
                                        onSave={(relayMember: RelayPerson) => {
                                            this.handleAddRelayMemberDialogOnSave(relayMember, arrayHelpers);
                                        }}
                                        onAbort={() => {
                                            this.handleAddRelayMemberDialogOnAbort();
                                        }}
                                    />
                                }
                            </>
                        )} />
                </>
                }
                {this.state && this.state.confirmResendStartCertificate && this.renderConfirmResendStartCertificate()}
            </Grid>
        </>);
    }

    renderConfirmResendStartCertificate = () => {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-entry-title"
            open={true}
        >
            <DialogTitle id="edit-entry-title">Skicka om startbevis</DialogTitle>
            <DialogContent>
                <Typography variant="body2">Är du säker på att du vill skicka om startbeviset till denna deltagare?</Typography>
                <Button onClick={async () => {
                    const back = new ApiBackend();
                    await back.resendStartCertificate(this.props.values.id);
                    this.setState({ confirmResendStartCertificate: false }, () => {
                        this.props.onRequestReloadEntry();
                    });
                }}>Ja, skicka om</Button>
                <Button onClick={() => { this.setState({ confirmResendStartCertificate: false }) }}>Avbryt</Button>
            </DialogContent>
        </Dialog>;
    }

    private moveSlotUp = (idx: number, arrayHelpers: FieldArrayRenderProps): void => {
        arrayHelpers.move(idx, idx - 1);
    }

    private moveSlotDown = (idx: number, arrayHelpers: FieldArrayRenderProps): void => {
        arrayHelpers.move(idx, idx + 1);
    }

    private unassignSlot = (idx: number, arrayHelpers: FieldArrayRenderProps, relayMember: RelayPerson): void => {
        if (!relayMember.personId) {
            return;
        }
        arrayHelpers.replace(idx, emptyRelayMember());
    }

    private assignToTeamLeader = (idx: number, arrayHelpers: FieldArrayRenderProps, entry: Entry): void => {
        if (!(entry.personId && entry.personId.length)) {
            alert("Lagledaren saknar 'personId' och kan därför inte tilldelas en plats");
            return;
        }

        arrayHelpers.replace(idx, entryToRelayMember(entry, this.props.person));
    }

    private handleAddRelayMemberDialogOnSave = (relayMember: RelayPerson, arrayHelpers: ArrayHelpers): void => {
        arrayHelpers.replace(this.state.relayMemberIndex, relayMember);
        this.closeAddRelayRemberDialog();
    }

    private handleAddRelayMemberDialogOnAbort = (): void => {
        this.closeAddRelayRemberDialog();
    }

    private showAddRelayMemberDialog = (idx: number): void => {
        this.setState({
            showAddRelayMemberDialog: true,
            relayMemberIndex: idx
        });
    }

    private closeAddRelayRemberDialog = (): void => {
        this.setState({
            showAddRelayMemberDialog: false,
            relayMemberIndex: null
        });
    }

    private getProductVariantRaceData = (): ProductVariantRaceData => {
        const productVariant = this.props.entryProduct.variants.find(variant => variant.Id === this.props.values.variantId);

        return productVariant.productVariantRaceData;
    }

    private fixRelayMembersArrayIfMissingRows = (numberOfTeamMembersForRace: number, relayMembers: RelayPerson[]): void => {
        const hasLessMembersThanExpectedNumberOfTeamMembers = relayMembers.length < numberOfTeamMembersForRace;
        if (!hasLessMembersThanExpectedNumberOfTeamMembers) {
            return;
        }

        const numberOfMissingRelayMembers = numberOfTeamMembersForRace - relayMembers.length;

        [...Array(numberOfMissingRelayMembers)].forEach((_) => {
            relayMembers.push(emptyRelayMember());
        });
    }
}



export default EditEntryFormContent;
