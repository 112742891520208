import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, LinearProgress, useTheme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ApiBackend } from "../../../providers/apibackend";
import { Order } from "../../../model/Order";
import { getPaymentDiff } from "./utils";
import CardPaymentStep from "./CardPaymentStep";
import { PointOfSale } from "../../../model/PointOfSale";
import SwishPaymentStep from "./SwishPaymentStep";
import { Alert } from "@material-ui/lab";
import { OrderContact } from "../../../model/OrderContact";

type PayWith = "card" | "swish";

interface IProps {
    orderId: string;
    onAbort: () => void;
    onComplete: () => void;
}

const OrderPaymentDialog = ({ orderId, onAbort, onComplete }: IProps) => {
    const [kortterminalPos, setKortterminalPos] = useState(null as PointOfSale);
    const [order, setOrder] = useState(null as Order);
    const [orderContact, setOrderContact] = useState(null as OrderContact);
    const [payWith, setPayWith] = useState(null as PayWith);
    const [swishPaymentStatus, setSwishPaymentStatus] = useState("");
    const [loading, setLoading] = useState(false);

    const busyLoading = useRef<boolean>(false);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();
    const theme = useTheme();

    const setBusyLoading = (value: boolean): void => {
        busyLoading.current = value;
        setLoading(value);
    };

    useEffect(() => {
        didMount.current = true;

        init();

        // unmount
        return () => { didMount.current = false };
    }, []);

    const init = async (): Promise<void> => {
        if (!didMount.current) {
            return;
        }

        setBusyLoading(true);

        const work = [
            backend.retreiveOrder(orderId),
            backend.getKortterminalVlPointOfSale()
        ];

        const [orderResponse, kortterminalResponse] = await Promise.all(work);

        if (didMount.current) {
            setOrder(orderResponse as Order);
            setOrderContact((orderResponse as Order).contact);
            setKortterminalPos(kortterminalResponse as PointOfSale);
            setBusyLoading(false);
        }
    };

    const handleCardComplete = async (paymentComment: string) => {
        await backend.addOrderPayment({
            orderId: order.id,
            paymentType: "PAYMENT",
            amount: getPaymentDiff(order),
            pointOfSaleId: kortterminalPos.id,
            description: paymentComment
        });

        onComplete();
    }

    const handleSwishComplete = () => {
        onComplete();
    };

    const handleAbort = () => {
        onAbort();
    };

    const renderPayment = () => {
        const diff = getPaymentDiff(order);
        const hasToPay = diff > 0;

        if (!hasToPay) {
            return <Alert severity="info">Det finns inget utestående att betala på ordern</Alert>
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="info">{diff}kr återstår att betala på ordern</Alert>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel label="Kortbetalning"
                        control={
                            <Checkbox
                                checked={payWith == "card"}
                                onChange={() => {
                                    setPayWith("card");
                                }}
                            />
                        }
                    />
                    <FormControlLabel label="Swish"
                        control={
                            <Checkbox
                                checked={payWith == "swish"}
                                onChange={() => {
                                    setPayWith("swish");
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    {payWith === "card" &&
                        <CardPaymentStep
                            price={getPaymentDiff(order)}
                            onComplete={handleCardComplete}
                        />
                    }
                    {payWith === "swish" &&
                        <SwishPaymentStep
                            phone={orderContact.phone}
                            initializePaymentDisabled={false}
                            onPhoneChange={(value) => {
                                orderContact.phone = value;
                                setOrderContact({ ...orderContact });
                            }}
                            onRequestCreateOrder={async () => {
                                let ret = order;
                                if (orderContact.phone !== order.contact.phone) {
                                    // Update order with new contact and return order
                                    const nextOrder = await backend.updateOrderContact(order.id, orderContact);
                                    setOrder(nextOrder);
                                    ret = nextOrder;
                                }

                                return ret;
                            }}
                            onProgress={(message) => {
                                setSwishPaymentStatus(message);
                            }}
                            onComplete={handleSwishComplete}
                            onAbort={() => {
                                setSwishPaymentStatus("");
                            }}
                        />
                    }
                </Grid>
                {swishPaymentStatus && <>
                    <Grid item xs={12}
                        style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}>
                        <CircularProgress />
                        {swishPaymentStatus}
                    </Grid>
                </>}
            </Grid>
        );
    };

    const render = (): JSX.Element => {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="order-payment-dialog-title"
                open={true}>
                <DialogTitle id="order-payment-dialog-title">
                    Betala order
                </DialogTitle>
                <DialogContent dividers>
                    {loading &&
                        <LinearProgress color="secondary" />
                    }
                    {order &&
                        renderPayment()
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ marginLeft: 5 }}
                        onClick={handleAbort}
                    >
                        Avbryt
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return render();
};

export default OrderPaymentDialog
